import React, { useState, useEffect } from "react";
import "./LogIn.css";
import image from "../../img/process-cuate.png";
import image1 from "../../img/google-png-0-1.png";
import image2 from "../../img/DHMS_logo.png";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  
  // Material UI Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  
  const navigate = useNavigate();

  // Function to show snackbar
  const displaySnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Function to hide snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Check for remembered email on component mount
  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    
    try {
      // Basic validation
      if (!email || !password) {
        throw new Error("Email and Password are required!");
      }

      // Email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        throw new Error("Please enter a valid email address");
      }
      
      const response = await fetch("https://dhms.itservicedeskafrica.com/api/technicianlogin", {
        method: "POST",
        mode: "cors", // Enable CORS
        credentials: "include", // Include cookies for cross-site requests
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Origin": window.location.origin, // Send origin header
          "X-Requested-With": "XMLHttpRequest" // Helps prevent CSRF
        },
        body: JSON.stringify({ technicianEmail: email, password }),
      });
      
      const data = await response.json();
      
      if (response.ok) {
        // Store token securely
        if (data.Token?.access) {
          localStorage.setItem("accessToken", data.Token.access);
        } else if (data.token) {
          localStorage.setItem("accessToken", data.token);
        }

        // Handle remember me
        if (rememberMe) {
          localStorage.setItem("rememberedEmail", email);
        } else {
          localStorage.removeItem("rememberedEmail");
        }

        // Show success snackbar
        displaySnackbar("Login successful! Redirecting to dashboard...", "success");
        
        // Give time for the snackbar to be seen before redirecting
        setTimeout(() => {
          // Redirect to dashboard
          navigate("/dashboard");
        }, 1500);
      } else {
        // Handle specific CORS-related errors
        if (response.status === 0) {
          throw new Error("CORS error: Server not accessible or CORS not configured properly");
        }
        // Login failed
        setError(data.message || `Error: ${response.status}`);
        displaySnackbar(data.message || "Login failed", "error");
      }
    } catch (err) {
      setError(err.message || "Network error. Please try again later.");
      displaySnackbar(err.message || "Network error. Please try again later.", "error");
      console.error("Login error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      {/* Material UI Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Left section with logo and illustration */}
      <div className="left-section">
        <div className="logo-container">
          <img src={image2} alt="DHMS" className="dhms-logo" />
        </div>
        
        <div className="illustration-container">
          <img src={image} alt="IT Service Process" className="process-illustration" />
        </div>
      </div>

      {/* Right section with login form */}
      <div className="right-section">
        <div className="login-form-wrapper">
          <div className="login-header">
            <h1>Welcome Back</h1>
            <p>Log into your account</p>
          </div>

          <form onSubmit={handleLogin} className="login-form">
            {error && <div className="error-message">{error}</div>}
            
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <div className="password-input-containe">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Create a password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                      <line x1="1" y1="1" x2="23" y2="23"></line>
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                  )}
                </button>
              </div>
            </div>

            <div className="remember-me">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <span className="checkmark"></span>
                Remember me?
              </label>
            </div>

            <button type="submit" className="login-button" disabled={loading}>
              {loading ? "Logging in..." : "Log in"}
            </button>

            <div className="forgot-password1">
              <a href="/ResetPassword">Forgot Password?</a>
            </div>

            <div className="divider">
              <span>or</span>
            </div>

            <button type="button" className="google-login-button">
              <img src={image1} alt="Google" className="google-icon" />
              Login with google
            </button>

            <div className="signup-prompt">
              Don't have an account? <a href="/SignUp">Create account</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;