import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert, Button, CircularProgress } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

/**
 * Function to handle technician logout
 * @returns {Promise<Object>} The result of the logout operation
 */
const logoutTechnician = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    
    // Clear tokens before making request for security reasons
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    
    const response = await fetch("https://dhms.itservicedeskafrica.com/api/technicianlogout", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    console.log("Logout API Response:", data);

    if (!response.ok) {
      return {
        success: false,
        message: data.message || `Error: ${response.status} ${response.statusText}`,
        code: response.status,
      };
    }

    return { success: true, message: "Logout successful!" };
  } catch (error) {
    console.error("Logout error:", error);
    return {
      success: false,
      message: "Network or server error occurred during logout. You have been logged out locally.",
      code: 500,
    };
  }
};

/**
 * Logout button component
 */
export function LogoutButton({ variant = "contained", color = "error", size = "medium" }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleLogout = async () => {
    setLoading(true);
    const logoutResponse = await logoutTechnician();
    
    setSnackbarMessage(logoutResponse.message);
    setSnackbarSeverity(logoutResponse.success ? "success" : "warning");
    setSnackbarOpen(true);
    
    // Redirect after a delay
    setTimeout(() => navigate("/"), 1500);
    setLoading(false);
  };

  return (
    <>
      <Button
        variant={variant}
        color={color}
        onClick={handleLogout}
        disabled={loading}
        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <LogoutIcon />}
        size={size}
      >
        {loading ? "Logging out..." : "Logout"}
      </Button>
      
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

/**
 * Logout page component
 */
export default function LogoutPage() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [loading, setLoading] = useState(true);
  
  const navigate = useNavigate();
  
  useEffect(() => {
    const performLogout = async () => {
      const logoutResponse = await logoutTechnician();
      setSnackbarMessage(logoutResponse.message);
      setSnackbarSeverity(logoutResponse.success ? "success" : "warning");
      setSnackbarOpen(true);
      setTimeout(() => navigate("/"), 2000);
      setLoading(false);
    };
    performLogout();
  }, [navigate]);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minHeight: "100vh" }}>
      <h1>{loading ? "Logging Out..." : "Logged Out"}</h1>
      <p>{loading ? "Please wait while we log you out..." : "Redirecting to login page..."}</p>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

// Exporting logout function
export { logoutTechnician };
