// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
// import { SidebarProvider } from './components/sidebar/SidebarContext';
// import Sidebar from './components/sidebar/Sidebar';
// import Topbar from './components/topbar/Topbar';
// import MaintenanceRequest from './pages/MaintenanceRequest/MaintenanceRequest';
// import PartsManagement from './pages/PartsManagement/PartsManagement';
// import Dashboard from './pages/home/Dashboard';
// import Rightbar from './pages/right/Rightbar';
// import RightbarTwo from './pages/right/RightbarTwo';
// import RightbarThree from './pages/right/RightbarThree';
// import Wallet from './pages/Wallet/Wallet';
// import Help from './pages/HelpSupport/HelpSupport';
// import Setting from './pages/Setting/Setting';
// import FAQs from './pages/FAQs/FAQ';
// import Loader from './components/Loader/Loader';
// import LogIn from './pages/Login/LogIn';
// import SignUp from './pages/signup/SignUp';
// import ResetPassword from './pages/ResetPassword/ResetPassword';
// import Security from '../src/pages/Setting/Security';
// import LogoutPage from '../src/pages/LogOut/LogOut'; 

// import './app.css';

// // Protected Route component to handle authentication
// const ProtectedRoute = ({ children }) => {
//   const accessToken = localStorage.getItem('accessToken');
  
//   if (!accessToken) {
//     // Redirect to login if not authenticated
//     return <Navigate to="/" replace />;
//   }
  
//   return children;
// };

// function AppContent() {
//   const location = useLocation();
//   const [loading, setLoading] = useState(true);

//   // Simple loading effect
//   useEffect(() => {
//     const timer = setTimeout(() => setLoading(false), 200);
//     return () => clearTimeout(timer);
//   }, [location]);

//   // Routes configuration
//   const noRightbarRoutes = [
//     '/MaintenanceRequest',
//     '/PartsManagement',
//     '/wallet',
//     '/help',
//     '/settings',
//     '/faqs',
//     '/security',
//     '/logout' 
//   ];
//   const authRoutes = ['/', '/SignUp', '/ResetPassword', '/logout']; 
  
//   const isAuthRoute = authRoutes.includes(location.pathname);
//   const showRightbars = !noRightbarRoutes.includes(location.pathname) && !isAuthRoute;

//   if (loading) return <Loader />;

//   return (
//     <div className="appContainer">
//       {!isAuthRoute && <Topbar />}
//       <div className="container">
//         {!isAuthRoute && <Sidebar />}
//         <div className="contentWrapper">
//           <div className="mainContent1">
//             <Routes>
//               {/* Auth Routes */}
//               <Route path="/" element={<LogIn />} />
//               <Route path="/SignUp" element={<SignUp />} />
//               <Route path="/ResetPassword" element={<ResetPassword />} />
//               <Route path="/logout" element={<LogoutPage />} />

//               {/* Protected Routes */}
//               <Route 
//                 path="/dashboard" 
//                 element={
//                   <ProtectedRoute>
//                     <Dashboard />
//                   </ProtectedRoute>
//                 } 
//               />
//               <Route 
//                 path="/MaintenanceRequest" 
//                 element={
//                   <ProtectedRoute>
//                     <MaintenanceRequest />
//                   </ProtectedRoute>
//                 } 
//               />
//               <Route 
//                 path="/PartsManagement" 
//                 element={
//                   <ProtectedRoute>
//                     <PartsManagement />
//                   </ProtectedRoute>
//                 } 
//               />
//               <Route 
//                 path="/wallet" 
//                 element={
//                   <ProtectedRoute>
//                     <Wallet />
//                   </ProtectedRoute>
//                 } 
//               />
//               <Route 
//                 path="/help" 
//                 element={
//                   <ProtectedRoute>
//                     <Help />
//                   </ProtectedRoute>
//                 } 
//               />
//               <Route 
//                 path="/settings" 
//                 element={
//                   <ProtectedRoute>
//                     <Setting />
//                   </ProtectedRoute>
//                 } 
//               />
//               <Route 
//                 path="/faqs" 
//                 element={
//                   <ProtectedRoute>
//                     <FAQs />
//                   </ProtectedRoute>
//                 } 
//               />
//               <Route 
//                 path="/security" 
//                 element={
//                   <ProtectedRoute>
//                     <Security />
//                   </ProtectedRoute>
//                 } 
//               />

//               {/* Default Route - redirect to login if not authenticated */}
//               <Route path="*" element={<Navigate to="/" replace />} />
//             </Routes>
//           </div>

//           {showRightbars && (
//             <div className="Rightbars">
//               <Rightbar percentage={90} />
//               <RightbarTwo />
//               <RightbarThree />
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// function App() {
//   return (
//     <Router>
//       <SidebarProvider>
//         <AppContent />
//       </SidebarProvider>
//     </Router>
//   );
// }

// export default App;



import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { SidebarProvider } from './components/sidebar/SidebarContext';
import Sidebar from './components/sidebar/Sidebar';
import Topbar from './components/topbar/Topbar';
import MaintenanceRequest from './pages/MaintenanceRequest/MaintenanceRequest';
import PartsManagement from './pages/PartsManagement/PartsManagement';
import Dashboard from './pages/home/Dashboard';
import Rightbar from './pages/right/Rightbar';
import RightbarTwo from './pages/right/RightbarTwo';
import RightbarThree from './pages/right/RightbarThree';
import Wallet from './pages/Wallet/Wallet';
import Help from './pages/HelpSupport/HelpSupport';
import Setting from './pages/Setting/Setting';
import FAQs from './pages/FAQs/FAQ';
import Loader from './components/Loader/Loader';
import LogIn from './pages/Login/LogIn';
import SignUp from './pages/signup/SignUp';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Security from '../src/pages/Setting/Security';
import LogoutPage from '../src/pages/LogOut/LogOut'; 

import './app.css';

function AppContent() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  // Simple loading effect
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 200);
    return () => clearTimeout(timer);
  }, [location]);

  // Routes configuration
  const noRightbarRoutes = [
    '/MaintenanceRequest',
    '/PartsManagement',
    '/wallet',
    '/help',
    '/settings',
    '/faqs',
    '/security',
    '/logout' 
  ];
  const authRoutes = ['/', '/SignUp', '/ResetPassword', '/logout']; 
  
  const isAuthRoute = authRoutes.includes(location.pathname);
  const showRightbars = !noRightbarRoutes.includes(location.pathname) && !isAuthRoute;

  if (loading) return <Loader />;

  return (
    <div className="appContainer">
      {!isAuthRoute && <Topbar />}
      <div className="container">
        {!isAuthRoute && <Sidebar />}
        <div className="contentWrapper">
          <div className="mainContent1">
            <Routes>
              {/* Auth Routes */}
              <Route path="/" element={<LogIn />} />
              <Route path="/SignUp" element={<SignUp />} />
              <Route path="/ResetPassword" element={<ResetPassword />} />
              <Route path="/logout" element={<LogoutPage />} />

              {/* Removed ProtectedRoute wrapper */}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/MaintenanceRequest" element={<MaintenanceRequest />} />
              <Route path="/PartsManagement" element={<PartsManagement />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/help" element={<Help />} />
              <Route path="/settings" element={<Setting />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/security" element={<Security />} />

              {/* Default Route */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>

          {showRightbars && (
            <div className="Rightbars">
              <Rightbar percentage={90} />
              <RightbarTwo />
              <RightbarThree />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <SidebarProvider>
        <AppContent />
      </SidebarProvider>
    </Router>
  );
}

export default App;