/* eslint-disable jsx-a11y/anchor-is-valid */
import './sidebar.css';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import BuildIcon from '@mui/icons-material/Build';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BarChartIcon from '@mui/icons-material/BarChart';
import HelpIcon from '@mui/icons-material/Help';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSidebar } from './SidebarContext';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { logoutTechnician } from '../../pages/LogOut/LogOut'; 

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { isSidebarOpen, setIsSidebarOpen } = useSidebar();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [loading, setLoading] = useState(false);

  const narrowSidebarRoutes = ['/MaintenanceRequest', '/anotherPage', '/otherPage'];
  const isNarrowSidebar = narrowSidebarRoutes.includes(location.pathname);

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleClick = (path, e) => {
    setActiveLink(path);
    
    // Special handling for logout
    if (path === '/logout') {
      e.preventDefault(); // Prevent default navigation behavior
      handleLogout();
      return;
    }
    
    if (isMobile) {
      setIsSidebarOpen(false); // Close sidebar on mobile after clicking a link
    }
  };

  const handleLogout = async () => {
    setLoading(true);
    
    try {
      const logoutResponse = await logoutTechnician();
      
      setSnackbarMessage(logoutResponse.message);
      setSnackbarSeverity(logoutResponse.success ? "success" : "warning");
      setSnackbarOpen(true);
      
      // Redirect to login page after a short delay
      setTimeout(() => {
        navigate("/");
      }, 1000);
      
    } catch (error) {
      console.error("Logout handling error:", error);
      setSnackbarMessage("An unexpected error occurred. You have been logged out.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      
      // Still redirect to login
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={`sidebar ${isNarrowSidebar ? 'sidebar-narrow' : ''} ${isSidebarOpen ? 'open' : ''}`}>
        {isMobile && isSidebarOpen && (
          <IconButton 
            className="closeSidebarButton"
            onClick={() => setIsSidebarOpen(false)}
            sx={{ 
              position: 'absolute',
              right: '10px',
              top: '10px',
              color: 'white'
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        
        <div className="sidebarWrapper">
          <div className="sidebarMenu">
            <ul className="sidebarList">
              <li className={`sidebarListItem ${activeLink === '/dashboard' ? 'active' : ''}`}>
                <a href="/dashboard" className="sidebarLink" onClick={(e) => handleClick('/dashboard', e)}>
                  <QrCodeIcon className='sidebarIcon' /> Dashboard
                </a>
              </li>
              <li className={`sidebarListItem ${activeLink === '/MaintenanceRequest' ? 'active' : ''}`}>
                <a href="/MaintenanceRequest" className="sidebarLink" onClick={(e) => handleClick('/MaintenanceRequest', e)}>
                  <DynamicFeedIcon className='sidebarIcon' /> Maintenance Request
                </a>
              </li>
              <li className={`sidebarListItem ${activeLink === '/PartsManagement' ? 'active' : ''}`}>
                <a href="/PartsManagement" className="sidebarLink" onClick={(e) => handleClick('/PartsManagement', e)}>
                  <BuildIcon className='sidebarIcon' /> Parts Management
                </a>
              </li>
              <li className={`sidebarListItem ${activeLink === '/wallet' ? 'active' : ''}`}>
                <a href="/wallet" className="sidebarLink" onClick={(e) => handleClick('/wallet', e)}>
                  <AccountBalanceWalletIcon className='sidebarIcon' /> Wallet
                </a>
              </li>
              <li className={`sidebarListItem ${activeLink === '/analytics' ? 'active' : ''}`}>
                <a href="/analytics" className="sidebarLink" onClick={(e) => handleClick('/analytics', e)}>
                  <BarChartIcon className='sidebarIcon' /> Analytics
                </a>
              </li>
              <li className={`sidebarListItem ${activeLink === '/help' ? 'active' : ''}`}>
                <a href="/help" className="sidebarLink" onClick={(e) => handleClick('/help', e)}>
                  <HelpIcon className='sidebarIcon' /> Help and support
                </a>
              </li>
              <li className={`sidebarListItem ${activeLink === '/settings' ? 'active' : ''}`}>
                <a href="/settings" className="sidebarLink" onClick={(e) => handleClick('/settings', e)}>
                  <SettingsIcon className='sidebarIcon' /> Settings
                </a>
              </li>
              <li className={`sidebarListItem logOut ${loading ? 'disabled' : ''}`}>
                <a 
                  href="#" 
                  className="sidebarLink" 
                  onClick={(e) => handleClick('/logout', e)}
                  style={{ opacity: loading ? 0.6 : 1, pointerEvents: loading ? 'none' : 'auto' }}
                >
                  <LogoutIcon className='sidebarIcon' /> {loading ? 'Logging out...' : 'Log out'}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Sidebar;
